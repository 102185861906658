<template>
  <div class="text-left">
    <AccountActionResultStatus>
      <template #header>로그인</template>
    </AccountActionResultStatus>
    <LoginForm class="mt-10" />
    <div class="flex items-center justify-between">
      <div class="flex gap-x-6">
        <Button v-bind="buttonConfig" :to="{ name: 'auth-find-account' }" value="계정 찾기" />
        <Button v-bind="buttonConfig" :to="{ name: 'auth-password-reset' }" value="비밀번호 찾기" />
      </div>
      <SignLinkGroup size="lg" exclude-login />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ButtonProps } from '~/components/ui/button/types'

definePageMeta({
  layout: 'auth',
  skipAuth: true
})

const buttonConfig: ButtonProps = {
  as: 'NuxtLink',
  size: 'lg',
  variant: 'link',
  color: 'slate'
}
</script>

<template>
  <div>
    <FormKit
      id="loginForm"
      v-slot="{ value }"
      novalidate
      type="form"
      :actions="false"
      :incomplete-message="false"
      :value="{
        email: '',
        password: ''
      }"
      @submit="submitHandler">
      <FormKit
        type="email"
        :size
        label="이메일"
        name="email"
        validation="required|email"
        :validation-messages="{
          required: '이메일을 입력하세요.',
          email: '이메일 형식을 확인해 주세요.'
        }"
        placeholder="이메일을 입력하세요."
        :errors="errors?.email">
        <template #messages="{ messages }">
          <div v-for="message in messages" :key="message.key">
            <div
              v-if="message.key === 'rule_required' || message.key === 'rule_email'"
              class="mt-2 flex items-center gap-x-1 text-red-600">
              <Icon name="heroicons:exclamation-circle" class="h-4 w-4" />
              <span class="text-xs">{{ message.value }}</span>
            </div>
          </div>
        </template>
      </FormKit>
      <FormKit
        type="password"
        :size
        label="비밀번호"
        name="password"
        placeholder="••••••••••"
        validation="required"
        :sections-schema="{
          messages: {
            attrs: {
              style: { display: 'none' }
            }
          }
        }"
        :errors="errors?.password" />
      <Button
        type="submit"
        size="lg"
        :disabled="!value?.email || !value?.password"
        class="mb-6 w-full"
        value="로그인"
        @click="submitForm('loginForm')" />
    </FormKit>
  </div>
</template>

<script setup lang="ts">
import { getNode, submitForm } from '@formkit/core'
import { useFormKitContextById } from '@formkit/vue'
import { useMutation } from '@tanstack/vue-query'
import { useAPI } from '~/composables/shared/useAPI'
import { useTokenCookies } from '~/composables/shared/useTokenCookies'
import { TOKEN } from '~/constants/shared'
import { handleFormError } from '~/utils/form'
import type { LoginFormProps } from './types'
withDefaults(defineProps<LoginFormProps>(), {
  size: 'md'
})
const api = useAPI()
const { cookie } = useTokenCookies()
const { mutate: submitHandler } = useMutation({
  mutationFn: () => {
    const loginForm = useFormKitContextById('loginForm')
    return api.auth.authToken.login({ body: loginForm.value?.value })
  },
  onSuccess: (data) => {
    const config = useRuntimeConfig()
    cookie.set(TOKEN.AUTH.value, data.token, TOKEN.AUTH.option(config.public.baseCookieDomain))
    api.worker.tenant.list().then((response) => {
      if (response?.results?.length > 0) {
        cookie.set(
          TOKEN.TENANT.value,
          response.results[response.results.length - 1].code,
          TOKEN.TENANT.option(config.public.baseCookieDomain)
        )
        return navigateTo(`/projects`)
      } else {
        return navigateTo({
          name: 'data-lab-role',
          params: { role: 'labeler' }
        })
      }
    })
  },
  onError: (err) => {
    const loginForm = getNode('loginForm')
    const response = err.response._data
    handleFormError(loginForm, response)
    Object.keys(response).forEach((key) => {
      if (key === 'non_field_errors') {
        loginForm?.setErrors([], {
          email: '',
          password: ''
        })
      }
    })
  }
})
</script>
